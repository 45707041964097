// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeErase,
    placeLabel,
    placeDash,
    placeTapeHori,
    placeTapeVert,
    placeText,
    placeHTMLText,
    placeAngle
} from '../../../../common/edliy_utils-geometric';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 12, 10, -8],
    keepaspectratio: true, axis:true, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  //brd1.suspendUpdate();
  makeResponsive(brd1);
  brd1.options.layer['text'] =30;
  brd1.options.layer['image'] =30;
  brd1.options.layer['point'] =20;
  brd1.options.layer['line'] =15;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Distance Formula', 'Find distance between points A and B');
  placeLogo(brd1);
  var i=0; var j=0;
  ////////////////////////////////////////////////////////////////////////////////
      var analytics = placeUndo(brd1);
      analytics.setAttribute({visible:()=>i>0})
      analytics.setLabel('Tap to Go to Previous Step');
      analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      analytics.on('over', function () {this.label.setAttribute({visible:true});});
      analytics.on('out', function () {this.label.setAttribute({visible:false});});
      ////////////////////////////////////////////////////
      var erase = placeRedo(brd1);
      erase.setLabel('Tap to Go to Next Step')
      erase.setAttribute({visible:()=>i<3})
      erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      erase.on('over', function () {this.label.setAttribute({visible:true});});
      erase.on('out', function () {this.label.setAttribute({visible:false});});
  /////////////////////////////////////////////////////////////////////////////
      var shuffle = placeShuffle(brd1);
      shuffle.setAttribute({visible:()=>i==0})
      shuffle.setLabel('Tap to Shuffle Events');
      shuffle.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      shuffle.on('over', function () {this.label.setAttribute({visible:true});});
      shuffle.on('out', function () {this.label.setAttribute({visible:false});});
///////////////////////////////////////////////////////////////////////////////////
      var shuffle2 = placeErase(brd1);
      shuffle2.setAttribute({visible:()=>i==3})
      shuffle2.setLabel('Tap to Start Over');
      shuffle2.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      shuffle2.on('over', function () {this.label.setAttribute({visible:true});});
      shuffle2.on('out', function () {this.label.setAttribute({visible:false});});
////////////////////////////////////////////
var pts1 = brd1.create('point', [2., 2.], {name:'', highlight:false, size:function(){return 4*brd1.canvasHeight/800}, fillColor:'orange', strokeColor:'black', strokeWidth:1, visible:true, snapToGrid:true, shadow:true});
placeLabel(brd1, pts1, 'A', 0, 15);
///////////////////////////////////////////////////////////////////////
var pts2 = brd1.create('point', [-3., -4.], {name:'', highlight:false, size:function(){return 4*brd1.canvasHeight/800}, fillColor:'orange', strokeColor:'black', strokeWidth:1, visible:true, snapToGrid:true, shadow:true});
placeLabel(brd1, pts2, 'B', -15, -15);
//////////////////////////////////////////////////////
var pts3 = brd1.create('point', [()=>pts1.X(), ()=>pts2.Y()], {name:'', highlight:false, size:function(){return 4*brd1.canvasHeight/800}, fillColor:'orange', strokeColor:'black', strokeWidth:1, visible:true, snapToGrid:true, shadow:true});
placeLabel(brd1, pts3, 'C', 15, -15);
pts3.setAttribute({visible:()=>i>=1})
//////////////////////////////////////////////////////////
placeLine(brd1, [()=>pts2.X(), ()=>pts2.Y()], [()=>pts1.X(), ()=>pts1.Y()], 3,'black');
var Horz =placeTapeHori(brd1, [()=>pts2.X(), ()=>pts2.Y()], [()=>pts1.X(), ()=>pts2.Y()], 'x2 - x1');
Horz.setAttribute({visible:()=>i>=2, highlight:true, fillOpacity:0.4,
  point1:{visible:false},point2:{visible:false}, strokeWidth:3, strokeColor:'red', label:{visible:()=>i>=5, color:'red'}});
/////
var horzDash =placeDash(brd1, [-12, ()=>pts2.Y()], [12, ()=>pts2.Y()], 2,'black');
horzDash.setAttribute({visible:()=>i>=1});
var vertDash = placeDash(brd1, [()=>pts1.X(), -12], [()=>pts1.X(), 12], 2,'black');
vertDash.setAttribute({visible:()=>i>=1});
////////////////////////////////////////
var Vert =placeTapeVert(brd1, [()=>pts1.X(), ()=>pts1.Y()], [()=>pts1.X(), ()=>pts2.Y()], 'Y2 - Y1');
Vert.setAttribute({visible:()=>i>=2, highlight:true, fillOpacity:0.4,
  point1:{visible:false},point2:{visible:false}, strokeWidth:3, strokeColor:'blue', label:{visible:()=>i>=5, color:'blue'}});
/////////////////////////////////////////////////////////////
var xAxis =placeHTMLText(brd1, ()=>(pts1.X()+pts2.X())/2, ()=>pts2.Y()-0.5, 18, ()=>'X_B-X_A = '+(Horz.Value()).toFixed(2));
xAxis.setAttribute({color:'red', visible:()=>i>=2});

var yAxis =placeHTMLText(brd1, ()=>pts1.X()+0.25, ()=>(pts1.Y()+pts2.Y())/2, 18, ()=>'Y_B-Y_A = '+(Vert.Value()).toFixed(2));
yAxis.setAttribute({anchorX:'left', color:'blue', visible:()=>i>=2});

var ang = placeAngle(brd1, [()=>pts2.X(), ()=>pts2.Y()], [()=>pts1.X(), ()=>pts2.Y()], [()=>pts1.X(), ()=>pts1.Y()],
 1, 'green');
ang.setAttribute({label:{visible:false},visible:()=>i>=2});
//ang.setLabel({})
///////////////////////////////TAP OPERATIONS ////////////////////////////////
var eggOne = placeImage(brd1, '/assets/eggOne.svg',-8.75, 8.25, 0.5, 0);
eggOne.setAttribute({visible:()=>i>=1});

var textOne =placeHTMLText(brd1, -8, 8.5, 18, 'Construct right-angled triangle ACB');
textOne.setAttribute({anchorX:'left', CssStyle: 'fontFamily:Oswald;fontWeight:bold', visible:()=>i>=1})

var eggTwo = placeImage(brd1, '/assets/eggTwo.svg',-8.75, 6.75, 0.5, 0);
eggTwo.setAttribute({visible:()=>i>=2});

var textTwo =placeHTMLText(brd1, -8, 7., 18, 'Measure base and height of the triangle');
textTwo.setAttribute({anchorX:'left', CssStyle: 'fontFamily:Oswald;fontWeight:bold', visible:()=>i>=2})


var eggThree = placeImage(brd1, '/assets/eggThree.svg',-8.75, 5.25, 0.5, 0);
eggThree.setAttribute({visible:()=>i>=3});

var pyText =placeHTMLText(brd1, -8, 5.5, 18, 'Apply Pythagoras Theorem');
pyText.setAttribute({anchorX:'left', CssStyle: 'fontFamily:Oswald;fontWeight:bold', visible:()=>i>=3})

var cal =placeHTMLText(brd1, -8, 4.5, 18,'AB^2 = BC^2 + CA^2 = (X_B -X_A)^2 + (Y_B-Y_A)^2');
cal.setAttribute({visible:()=>i>=3, anchorX:'left', CssStyle: 'fontFamily:Oswald;fontWeight:regular'});
///////////////////////////////////////
var dal =placeHTMLText(brd1, -8, 3.5, 18,'AB = [(X_B -X_A)^2 + (Y_B-Y_A)^2]^1^/^2');
dal.setAttribute({visible:()=>i>=3, anchorX:'left',CssStyle:'fontFamily:Oswald;fontWeight:regular'});
////////////////////////////////////////////////////////
var val = placeText(brd1, -8, 2.5, 18,()=>'AB ='+(Math.pow(Horz.Value()*Horz.Value()+Vert.Value()*Vert.Value(), 0.5)).toFixed(2));
val.setAttribute({visible:()=>i>=3, anchorX:'left',CssStyle: 'fontFamily:Oswald;fontWeight:regular'});
/////////////////////////////////////
analytics.on('down', function(){
  if(i>0)
  {
  i-=1;
  }
  else
  {
    i=0;
  }
});
/////////////////////////////////////OPERATION 2 /////////////////////////////////
erase.on('down', function(){
  if(i<3)
  {
  i+=1;
  }
  else
  {
    i=3;
  }
})
///////////////////////////////////////////OPERATION 3 //////////////////////////////////////
shuffle.on('down', function(){
  i=0;
  pts1.moveTo([2+Math.round(3*Math.random()),2+Math.round(3*Math.random())]);
  pts2.moveTo([-5+Math.round(3*Math.random()),-4+Math.round(3*Math.random())]);
  if(j<4)
  {
  j+=1;
  }
  else
  {
  j=0;
  }
})
shuffle2.on('down', function(){
  i=0;
})
////////////////////////////////////////////END OF OPERATIONS///////////////////////////////
    },


}
export default Boxes;
